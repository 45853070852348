body {
  background-color: rgb(246, 248, 250);
  overflow-y: hidden;
}
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000;
}
.looker-embed {
  width: 100%;
  min-height: 600px;
  border-width: 0px;
  height: calc(100vh - 49px);
}
.navbar {
  border-bottom: 1px solid #dedac6;
  padding: 0 20px 0 0;
  height: 50px;
  background: white;
  z-index: 999;
  position: relative;
}
.dropdown-menu {
  border-color: #dedac6;
}
.logo-top {
  line-height: 48px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.logo-top img {
  height: 48px;
}
.sidebar {
  border-right: 1px solid rgb(222, 218, 198);
  position: relative;
  overflow-y: auto;
  min-height: calc(100vh - 50px);
}
.grid-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas: '. .';
}
.collapse-side {
  position: absolute !important;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.sidebar-item-text {
  font-size: 0.8rem;
}
.sidebar-item-text.small {
  text-align: center;
  margin: 0;
  animation: slide 0.4s linear;
}
.sidebar-item-text.small button {
  padding: 2px;
}
@keyframes slide {
  from {
    transform: translateX(6.5px);
  }
  to {
    transform: translateX(0);
  }
}
.icon {
  animation: reverse-slide 0.4s linear;
}
.search-icon {
  animation: reverse-slide 0.4s linear;
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  left: 0;
  top: 2px;
}
@keyframes reverse-slide {
  from {
    transform: translateX(-6.5px);
  }
  to {
    transform: translateX(0);
  }
}
.sidebar-item-text > span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-item-text p.MuiTypography-body2 {
  font-size: 0.6rem;
}
a {
  color: #74c8b0;
}
a:hover {
  color: #333c4d;
  text-decoration: none;
}
.redirect-link {
  color: #fff;
}
.redirect-link:hover {
  color: #fff;
  text-decoration: underline;
}
.side-link {
  display: block;
  color: #1e4f61;
  white-space: nowrap;
  flex-grow: 1;
}
.side-link.active {
  color: #333c4d;
  transition-delay: 450ms;
  transition-property: color, background-color;
  transition: 450ms ease-in;
  background-color: rgba(0, 0, 0, 0.05);
}
.icon-color {
  color: #1e4f61;
}
.side-link.active .icon-color {
  color: #333c4d;
}
.content-style {
  position: absolute;
  right: 0;
}
button:focus {
  outline: none;
}
.green-button {
  background-color: #74c8b0;
  color: #333c4d;
  border-color: #23949d;
}
.green-button:focus {
  box-shadow: none;
}
.green-button:hover,
.green-button:active {
  background-color: #23949d !important;
  border-color: #23949d;
}
.green-button.disabled,
.green-button:disabled {
  background-color: #74c8b0 !important;
  border-color: #23949d !important;
}
.pixel-frame {
  width: 1px;
  height: 1px;
  position: absolute;
  z-index: -999;
}
.filter-container {
  border-bottom: 1px solid #dedac6;
  height: 65px;
}
.header-style {
  line-height: 65px;
  width: auto;
  text-overflow: ellipsis;
}
.org-navbar {
  max-height: 300px;
  overflow-y: scroll;
}
.full-width {
  width: 100%;
  max-width: 300px;
}
.menu-style {
  height: calc(100vh - 50px);
  transition: width 200ms linear;
  overflow-x: hidden;
  border-right: 1px solid rgb(222, 218, 198);
  position: relative;
  overflow-y: hidden;
  padding-bottom: 40px;
}
.right-menu-style {
  height: calc(100vh - 50px);
  transition: width 200ms linear;
  overflow-x: hidden;
  border-left: 1px solid rgb(222, 218, 198);
  position: relative;
  overflow-y: hidden;
}
#dash-wrapper {
  height: 100%;
  overflow-y: hidden;
}
.user-info-mobile {
  line-height: 40px;
  margin: 0px;
  padding-left: 7px;
  position: absolute;
  bottom: 0;
}
.active-tenant {
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.05);
}
.router-link-exact-active {
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.05);
}
.profile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px 12px;
}
.notification-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 24px 12px;
}
.notifications-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.wrapper {
  width: 60%;
  max-width: 760px;
  min-height: 318px;
  background-color: #fff;
  border-radius: 20px;
  padding: 24px 16px;
  margin: 24px 0;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.profile-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.profile-toogle {
  flex: 1 1 100%;
  display: flex;
  justify-content: flex-start;
}
.profile-toogle > div {
  flex-basis: auto;
}
.profile-toogle-button {
  padding: 6px 12px;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  transition: border 0.2s ease-in-out;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-toogle-button:hover,
.profile-toogle-button.active {
  border-bottom: 1px solid #000;
}
.profile-image {
  flex: 1 1 40%;
  display: flex;
  justify-content: center;
}
.profile-image img {
  width: 150px;
  border-radius: 50%;
}
.profile-information {
  flex: 1 1 60%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 12px 12px;
}
.profile-details {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0 12px;
}
.profile-details p {
  width: 100%;
  font-size: 1.2rem;
  margin: 0;
  padding-bottom: 12px;
  color: #1e5061;
}
.profile-details > div {
  width: 100%;
}
.profile-information > div {
  width: 300px;
}
.invitation-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.invitation-container {
  width: 60%;
  max-width: 500px;
  background-color: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 24px;
  margin: 24px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.invitation-button {
  margin: 5px;
  border: 1px solid #dedac6;
  color: black;
}
.chip-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0;
}
.header-contaniner {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.user-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.details-container {
  flex: 1 1 50%;
}
.edit-container {
  flex: 1 1 50%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.edit-container > * {
  flex: 1 1 100%;
  max-width: 100%;
}
.user-menu-dropdown {
  display: flex;
  align-items: center;
}
.info-dropdown { 
  display: flex;
  align-items: center;
}
.password-link {
  display: block;
  font-size: 14px;
  color: #74c8b0;
  padding-top: 10px;
  cursor: pointer;
}
.password-link:hover {
  color: #333c4d;
}
.table-header {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0;
  margin-right: 20px;
}
.table-header-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.modal-error {
  color: #f44336;
  padding-top: 10px;
  font-size: 12px;
}
.paper {
  max-height: 300px !important;
}
.toolbar-wrapper {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.active-chip {
  background-color: #fff !important;
  color: #1e4f61 !important;
  border-color: #1e4f61 !important;
}
.card-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 32px 12px;
}
.card {
  margin: 10px;
  cursor: pointer;
  padding: 20px;
  border-radius: 10px !important;
  min-height: 175px;
}
.card-image {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notifications-tag {
  padding: 0 5px;
  background: rgba(51, 60, 77, 0.4);
  color: #333c4d;
  border-radius: 3px;
  font-size: 12px;
}
.notification-link {
  text-transform: uppercase;
  color: #333c4d;
  cursor: pointer;
  text-decoration: underline;
}
.notification-item {
  border-top: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}
.notification-component-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.notification-component {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.notification-incident-update {
  padding: 10px 0;
}
.notification-header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
}
.notifications-dot {
  font-size: 10px !important;
  color: #EE3B32;
  position: absolute;
  top: 20px;
  right: 15px;
}
.component-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  width: 100%;
}
.chart-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.graph-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000;
}
.loading {
  position: absolute;
  left: 50%;
  height: 80px;
  padding: 0px;
  width: 110px;
  margin-top: -35px;
  margin-left: -55px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}
.loading .loading-combined {
  height: 10px;
  width: 20px;
  background-color: #ccc;
  display: inline-block;
  margin-top: 50px;
  margin-right: 2px;
  -webkit-animation: loading 1s infinite;
  -moz-animation: loading 1s infinite;
  -o-animation: loading 1s infinite;
  animation: loading 1s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.folder-toggler {
  margin: 0 10px;
  border: 1px solid #0000001A;
  border-radius: 4px;
  padding: 5px 15px;
  height: 40px;
}
.navbar-icon {
  color: #717273;
  pointer-events: none;
}
@keyframes loading {
  50% {
    height: 70px;
    margin-top:-10px;
  }
}
@-moz-keyframes loading {
  50% {
    height: 70px;
    margin-top: -10px;
  }
}
@-o-keyframes loading {
  50% {
    height: 70px;
    margin-top: -10px;
  }
}
.loading .loading-1 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.loading .loading-2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.loading .loading-3 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.loading .loading-4 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.Toastify__toast {
  border-radius: 10px;
}
.Toastify__toast--success {
  background-color: #74c8b0;
}
.Toastify__toast--error {
  background-color: #EE3B32;
}
.Toastify__toast--info {
  background-color: #1e4f61;
}
.MuiFormLabel-root.Mui-focused {
  color: #1e4f61 !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #1e4f61 !important;
}
.Mui-checked	{
  color: #74c8b0 !important;
}
@media only screen and (min-width: 1024.01px) {
  .wrapper {
    min-width: 700px;
  }
}
@media only screen and (max-width: 1024px) {
  .wrapper {
    width: auto;
    max-width: 760px;
  }
  .profile-details p {
    font-size: 1rem;
  }
  .profile-image {
    flex: 1 1 30%;
  }
  .profile-image img {
    width: 120px;
  }
  .profile-information {
    flex: 1 1 70%;
  }
  .invitation-container {
    width: 75%;
    min-height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .grid-container {
    display: flex;
  }
  .logo-top {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .navbar-toggler {
    margin-left: 5px;
  }
  .navbar {
    padding-right: 0px;
  }
  .menu-style {
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 1000;
    background-color: rgb(246, 248, 250);
    padding-bottom: 46px;
  }
  .right-menu-style {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1000;
    background-color: rgb(246, 248, 250);
  }
  .content-style {
    position: relative;
    flex: 1 1 100%;
  }
  .wrapper,
  .invitation-container {
    width: 70%;
  }
  .profile-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .profile-image {
    flex: 1 1 100%;
    justify-content: center;
    padding-bottom: 24px;
  }
  .profile-image img {
    width: 50%;
  }
  .mobile-list-item {
    white-space: nowrap;
  }
  .profile-information {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
  }
  .profile-information > div {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
  }
  .profile-details {
    justify-content: center;
  }
  .profile-details p {
    flex: 1 1 100%;
    font-size: 1rem;
    text-align: left;
    padding-left: 15px;
  }
  .profile-details > div {
    display: flex;
    justify-content: center;
  }
  .profile-details > div:nth-child(3) {
    justify-content: left;
    max-width: 300px;
  }
  .full-width {
    width: 80%;
  }
  .details-container {
    flex: 1 1 100%;
  }
  .edit-container {
    flex: 1 1 100%;
  }
  .user-info-mobile {
    bottom: 0;
  }
  .router-link-exact-active {
    font-weight: 600;
    background-color: inherit;
  }
  .backdrop {
    z-index: 10000 !important;
    background: transparent !important;
    height: 100vh;
    top: 0;
    position: absolute;
  }
}
@media only screen and (max-width: 576px) {
  .wrapper {
    width: 100%;
  }
  .invitation-container {
    width: 100%;
    margin: 24px 0;
  }
  .edit-container {
    padding-top: 10px;
    max-width: 100%;
  }
  .full-width {
    width: 100%;
  }
  .form-control-container {
    width: 100%;
  }
  .form-control-container.mobile {
    text-align: center;
  }
  .header-style {
    word-break: break-all;
  }
  .MuiToolbar-root {
    flex-wrap: wrap;
  }
  .table-header-container {
    padding-left: 16px;
  }
}
